import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactTypingEffect from "react-typing-effect";
const typing_subtitle = [
  "a Developer",
  "an Engineering Student",
  "a Programmer",
];

function Header() {
  return (
    <header className="main-header">
      <div className="absolute-image-container">
        <img
          src="/images/darpan-transparent-dashain.png"
          alt="Image of Darpan Kattel"
        />
      </div>
      <h1 className="website-title">Who is Darpan Kattel?</h1>
      <div className="typing-subtitle">
        <div className="content">
          I am{" "}
          <ReactTypingEffect
            text={typing_subtitle}
            className="main-text"
            speed={100}
            typingDelay={1000}
            eraseDelay={1000}
            eraseSpeed={50}
          />
        </div>
      </div>
      <div className="buttons-container">
        <Link
          to="/order"
          className="button button-primary"
          style={{ display: "block" }}
        >
          Order
        </Link>
        <Link
          to="/projects"
          className="button button-secondary"
          style={{ display: "block" }}
        >
          Projects
        </Link>
      </div>
    </header>
  );
}

export default Header;
