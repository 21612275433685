import React, { useState } from "react";
import "../styles/order.scss";
import { FiUser, FiMail } from "react-icons/fi";
import { AiOutlineNumber } from "react-icons/ai";
import { BsCash } from "react-icons/bs";
import { useMutation } from "react-query";
import { postOrder, getOrderStatus } from "../functions";
import { message } from "antd";
import OverlayLoader from "../components/OverlayLoader";
import ViewOrderStatus from "../components/ViewOrderStatus";
import ConfirmOrderPlaced from "../components/ConfirmOrderPlaced";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function Order({ darkMode }) {
  window.scrollTo(0, 0);
  const [searchParams, setSearchParams] = useSearchParams();
  const orderTypeFromUrl = searchParams.get("order_type") || "web";
  const [order, setOrder] = useState({
    name: "",
    email: "",
    order_type: orderTypeFromUrl,
    scale: "small",
    currency: "npr",
    intended_amount: "",
    message: "",
  });
  const [reference_number, setReferenceNumber] = useState("");
  const [viewOrderStatus, setViewOrderStatus] = useState({
    show: false,
    response: {},
  });
  const [viewOrderConfirmation, setViewOrderConfirmation] = useState({
    show: false,
    response: {},
  });

  const postOrderMutation = useMutation(
    async () => {
      return await postOrder({ order });
    },
    {
      onSuccess: (data) => {
        message.success("Ordered successfully!");
        console.log(data);
        console.log(data.data);
        setViewOrderConfirmation({ show: true, response: data.data });
        return setOrder({
          name: "",
          email: "",
          order_type: "web",
          scale: "small",
          currency: "npr",
          intended_amount: "",
          message: "",
        });
      },
      onError: (err) =>
        message.error(
          err.response.statusText || err.message || "Couldn't post your order!"
        ),
    }
  );
  const getOrderStatusMutation = useMutation(
    async () => {
      return await getOrderStatus(reference_number);
    },
    {
      onSuccess: (data) => {
        message.success("Order status fetched successfully!");
        console.log(data);
        console.log(data.data.status);
        setViewOrderStatus({
          show: true,
          response: data?.data,
        });
      },
      onError: (err) =>
        message.error(
          err.response.statusText ||
            err.message ||
            "Couldn't get your order status!"
        ),
    }
  );
  const submitOrder = (e) => {
    e.preventDefault();
    if (
      !(
        order.name &&
        order.email &&
        order.order_type &&
        order.scale &&
        order.currency &&
        order.intended_amount
      )
    ) {
      return message.error(
        "Please fill all the required field marked with '*'"
      );
    }
    postOrderMutation.mutate();
  };
  const submitReferenceId = (e) => {
    e.preventDefault();
    if (!reference_number) {
      return message.error(
        "You will need a reference number to track your order."
      );
    }
    getOrderStatusMutation.mutate();
  };
  return (
    <main className="main-container order">
      {postOrderMutation.isLoading && (
        <OverlayLoader text="Placing an order..." />
      )}
      {getOrderStatusMutation.isLoading && (
        <OverlayLoader text="Getting order status..." />
      )}
      {viewOrderStatus.show && (
        <ViewOrderStatus
          viewOrderStatus={viewOrderStatus}
          setViewOrderStatus={setViewOrderStatus}
        />
      )}
      {viewOrderConfirmation.show && (
        <ConfirmOrderPlaced
          viewOrderConfirmation={viewOrderConfirmation}
          setViewOrderConfirmation={setViewOrderConfirmation}
        />
      )}
      <div className="main-container-inner">
        <Helmet>
          <title>Create an Order - Darpan Kattel</title>
          <meta
            name="description"
            content="Fill up the order form to create an order. You can order a website, an desktop app or an mobile app."
          />
          <meta
            name="keywords"
            content="order, web, app, site, program, mobile, hire, developer, programmer"
          />
        </Helmet>
        <div className="titled-container">
          <h2 className="title">Make an Order</h2>
          <div className="container">
            <form onSubmit={submitOrder}>
              <div className="inputs-container mb-3">
                <div className="input-container">
                  <label>
                    <FiUser color={darkMode ? "#ffffff" : "#000000"} />
                  </label>
                  <input
                    type={"text"}
                    placeholder="Your Name*"
                    value={order.name}
                    onChange={(e) =>
                      setOrder({ ...order, name: e.target.value })
                    }
                  />
                </div>
                <div className="input-container">
                  <label>
                    <FiMail color={darkMode ? "#ffffff" : "#000000"} />
                  </label>
                  <input
                    type={"text"}
                    placeholder="Email*"
                    value={order.email}
                    onChange={(e) =>
                      setOrder({ ...order, email: e.target.value })
                    }
                  />
                </div>
                <div className="hint">
                  We will contact you through your email!
                </div>
              </div>

              <div className="inputs-container">
                <div className="select-container">
                  <label>Order Type*: </label>
                  <select
                    value={order.order_type}
                    onChange={(e) =>
                      setOrder({ ...order, order_type: e.target.value })
                    }
                  >
                    <option value={"web"}>Web</option>
                    <option value={"desktop"}>Desktop</option>
                    <option value={"mobile"}>Mobile</option>
                  </select>
                </div>
                <div className="select-container">
                  <label>Scale* : </label>
                  <select
                    value={order.scale}
                    onChange={(e) =>
                      setOrder({ ...order, scale: e.target.value })
                    }
                  >
                    <option value={"small"}>Small</option>
                    <option value={"intermediate"}>Intermediate</option>
                    <option value={"large"}>Large</option>
                  </select>
                </div>
              </div>

              <div className="inputs-container">
                <div className="select-container">
                  <label>Currency* : </label>
                  <select
                    value={order.currency}
                    onChange={(e) =>
                      setOrder({ ...order, currency: e.target.value })
                    }
                  >
                    <option value={"npr"}>NPR</option>
                    <option value={"usd"}>USD</option>
                  </select>
                </div>
                <div className="input-container">
                  <label>
                    <BsCash />
                  </label>
                  <input
                    type={"number"}
                    placeholder="Intended Amount*"
                    value={order.intended_amount}
                    onChange={(e) =>
                      setOrder({ ...order, intended_amount: e.target.value })
                    }
                  />
                </div>
                <div className="hint">
                  Currency and amounts can be changed later!
                </div>
              </div>
              <div className="input-container mt-3">
                <textarea
                  placeholder="Any message!"
                  onChange={(e) =>
                    setOrder({ ...order, message: e.target.value })
                  }
                  value={order.message}
                ></textarea>
              </div>

              <div className="buttons-container">
                <button type="submit" className="button button-primary">
                  Order
                </button>
                <input type={"reset"} className="button button-secondary" />
              </div>
            </form>
          </div>
        </div>
        <div className="OR">Or</div>
        <div className="titled-container track">
          <h2 className="title">Track your Order</h2>
          <div className="container">
            <form onSubmit={submitReferenceId}>
              <div className="input-container">
                <label>
                  <AiOutlineNumber color={darkMode ? "#ffffff" : "#000000"} />
                </label>
                <input
                  type={"text"}
                  placeholder="Reference id*"
                  value={reference_number}
                  onChange={(e) => setReferenceNumber(e.target.value)}
                />
              </div>
              <div className="buttons-container">
                <button type="submit" className="button button-primary">
                  Track
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Order;
