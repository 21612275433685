import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ProjectsHeader from "../components/ProjectsHeader";
import { FiExternalLink } from "react-icons/fi";
import { AiFillGithub } from "react-icons/ai";
import { Carousel, Image, Progress } from "antd";
import { useQuery } from "react-query";
import { getProject } from "../functions";
import Loader from "../components/Loader";
import FileViewerUI from "../components/FileViewerUI";
import { Helmet } from "react-helmet";

function ProjectView({ darkMode }) {
  const { slug } = useParams();
  const [project, setProject] = useState({});
  const { isFetching } = useQuery(["projects", slug], () => getProject(slug), {
    onSuccess: (data) => {
      return setProject(data.data);
    },
    onError: (err) => console.log(err),
    refetchOnWindowFocus: false,
  });
  window.scrollTo(0, 0);
  return isFetching ? (
    <Loader text="Loading...." />
  ) : (
    <main className="main-container projects project-view">
      <ProjectsHeader
        title={project?.title}
        subTitle={project?.desc}
        isProject={true}
      />
      <Helmet>
        <title>{project?.title} | Project - Darpan Kattel</title>
        <meta name="description" content={project?.desc} />
        <meta name="keywords" content={project?.keywords} />

        <meta
          property="og:title"
          content={`${project?.title} | Project - Darpan Kattel`}
        />
        <meta property="og:image" content={project?.thumbnail} />
        <meta property="og:description" content={project?.desc} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="main-container-inner">
        <div className="titled-container">
          {project?.website_link ? (
            <div className="view-website">
              <a href={project?.website_link} className="button button-primary">
                Visit Website <FiExternalLink />
              </a>
            </div>
          ) : null}
          <div className="container">
            <Carousel
              className="carousel-container"
              autoplay
              swipeToSlide={true}
              autoplaySpeed={2500}
            >
              <FileViewerUI title={project?.title + " - " + 1 + ".png"}>
                <div className="carousel-item">
                  <img
                    src={project?.thumbnail}
                    className="img"
                    alt={`Screenshot of ${project?.title}`}
                  />
                </div>
              </FileViewerUI>
              {project?.extra_image_1 && (
                <FileViewerUI title={project?.title + " - " + 2 + ".png"}>
                  <div className="carousel-item">
                    <img
                      src={project?.extra_image_1}
                      className="img"
                      alt={`Screenshot of ${project?.title}`}
                    />
                  </div>
                </FileViewerUI>
              )}
              {project?.extra_image_2 && (
                <FileViewerUI title={project?.title + " - " + 3 + ".png"}>
                  <div className="carousel-item">
                    <img
                      src={project?.extra_image_2}
                      className="img"
                      alt={`Screenshot of ${project?.title}`}
                    />
                  </div>
                </FileViewerUI>
              )}
            </Carousel>
          </div>
        </div>

        <div className="titled-container">
          <h2 className="title">About {project?.title}</h2>
          <p>{project?.about}</p>
        </div>
        <div className="friends-message-container">
          <div className="image-container">
            <img
              src={project?.quote_by?.image}
              alt={`Image of ${project?.quote_by?.full_name}`}
            />
          </div>
          <div className="message-container">
            <div className="message">
              <span className="big">"</span>
              {project?.quote}
              <span className="big">"</span>
            </div>
            <h3 className="title">{project?.quote_by?.full_name}</h3>
            <h4 className="sub-title">{project?.quote_by_relation}</h4>
          </div>
        </div>

        <div className="titled-container">
          <h2 className="title">Technical Sheet</h2>
          <div className="container technical-sheet-container">
            {project?.tech_used?.split(", ").map((language, index) => (
              <div key={index}>
                <Progress
                  strokeColor={darkMode ? "#ffc107" : "#f1974d"}
                  percent={project?.tech_used_percentage?.split(", ")[index]}
                  steps={10}
                  format={(percent) => (
                    <span
                      className={
                        darkMode ? "text text-dark" : "text text-light"
                      }
                    >
                      {language}
                      <span className="percent">{percent}%</span>
                    </span>
                  )}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="titled-container">
          <h2 className="title">References</h2>
          {project?.github_link ? (
            <a href={project?.github_link} className="reference">
              <AiFillGithub />
            </a>
          ) : (
            <p className="text">No references, it may be a private project!</p>
          )}
        </div>
      </div>
    </main>
  );
}

export default ProjectView;
