import React from "react";
import { Image } from "antd";
import { Link } from "react-router-dom";

function Project({ project }) {
  return (
    <Link
      className="project"
      to={"/projects/" + project?.slug}
      key={project?.slug}
    >
      <Image
        src={project?.thumbnail || "hehe.jpg"}
        width={"100%"}
        onClick={(event) => event.preventDefault()}
        className="img"
        alt={`Image of ${project?.title}`}
      />
      <h3 className="project-title">{project?.title}</h3>
      <h4 className="project-desc">{project?.desc}</h4>
      <div className="tags">
        {project?.tech_used?.split(", ").map((tag, index) => (
          <span className="tag" key={index}>
            {tag}
          </span>
        ))}
      </div>
    </Link>
  );
}

export default Project;
