import React from "react";
import { Spin } from "antd";

function Loader({ text = "Loading..." }) {
  return (
    <main className="main-container blog-view">
      <div className="spin-container">
        <Spin size="large" className="spin-dot" />
        <p className="spin-text">{text}</p>
      </div>
    </main>
  );
}

export default Loader;
