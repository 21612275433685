import { message } from "antd";
import React from "react";

function ConfirmOrderPlaced({
  viewOrderConfirmation,
  setViewOrderConfirmation,
}) {
  const { response } = viewOrderConfirmation;
  const copyToClipboard = () => {
    const element = document.querySelector("#copyToClipboard");
    element.select();
    element.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(element.value);
    message.success("Copied to clipboard!");
  };
  return (
    <div className="spin-container overlay">
      <div className="order-status-container">
        <p className="status">Order Placed</p>
        <p className="text">
          <span className="name">
            Mr./Mrs./Ms. {response?.name?.split(" ")[0]},
          </span>
          Your order has been placed and your reference number is,{" "}
          <span className="big-and-bold" onClick={() => copyToClipboard()}>
            #{response?.reference_number}
            <input
              type={"text"}
              style={{ display: "none" }}
              defaultValue={response?.reference_number}
              id="copyToClipboard"
            />
          </span>
          <small className="touch-to-copy">Touch the number to copy</small>
          You can track your order using this reference number.
        </p>
        <button
          className="button button-primary"
          onClick={() =>
            setViewOrderConfirmation({ show: false, response: {} })
          }
        >
          OK
        </button>
      </div>
    </div>
  );
}

export default ConfirmOrderPlaced;
