import React, { useState } from "react";
import "../styles/projects.scss";
import ProjectsHeader from "../components/ProjectsHeader";
import Project from "../components/Project";
import { useQuery } from "react-query";
import { getProjects } from "../functions";
import Loader from "../components/Loader";
import { Helmet } from "react-helmet";

function Projects() {
  window.scrollTo(0, 0);
  const [projectsData, setProjectsData] = useState([]);
  const { isFetching } = useQuery("projects", () => getProjects(), {
    onSuccess: (data) => setProjectsData(data.data),
    onError: (err) => console.log(err),
    refetchOnWindowFocus: false,
  });
  return isFetching ? (
    <Loader text="Loading Projects..." />
  ) : projectsData?.length > 0 ? (
    <main className="main-container projects">
      <Helmet>
        <title>Projects - Darpan Kattel</title>
        <meta
          name="description"
          content="All the mentionable Projects of Darpan Kattel is available here."
        />
        <meta
          name="keywords"
          content="projects, apps, websites, web, softwares, games, darpan, kattel, website"
        />
      </Helmet>
      <ProjectsHeader
        title={"Projects"}
        subTitle={"All that I have to mention!"}
      />
      <div className="main-container-inner">
        <div className="titled-container">
          <div className="container">
            {projectsData?.constructor === Array
              ? projectsData?.map((project) => (
                  <Project project={project} key={project.slug} />
                ))
              : null}
          </div>
        </div>
      </div>
    </main>
  ) : (
    "Unknown Error"
  );
}

export default Projects;
