import React, { useState } from "react";
import { contactDetails } from "../text_data";
import "../styles/contact.scss";

import { MdMailOutline, MdSubject } from "react-icons/md";
import { AiOutlineUser, AiOutlineMessage } from "react-icons/ai";
import { message as antMessage } from "antd";
import { useMutation } from "react-query";
import { sendMessageToBackend } from "../functions";
import OverlayLoader from "../components/OverlayLoader";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function Contact() {
  window.scrollTo(0, 0);
  const [searchParams, setSearchParams] = useSearchParams();
  const subjectFromUrl = searchParams.get("subject") || "";
  const [message, setMessage] = useState({
    name: "",
    email: "",
    subject: subjectFromUrl,
    message: "",
  });
  const sendMessageMutation = useMutation(
    async () => {
      return await sendMessageToBackend(message);
    },
    {
      onSuccess: () => {
        antMessage.success("Message sent successfully!");
        return setMessage({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      },
      onError: (err) =>
        antMessage.error(
          err.response.statusText ||
            err.message ||
            "Couldn't send your message!"
        ),
    }
  );
  const sendMessage = async (e) => {
    e.preventDefault();
    if (
      !(message.name && message.email && message.subject && message.message)
    ) {
      antMessage.error("Required fields must be filled!");
      return;
    }
    sendMessageMutation.mutate();
  };
  return (
    <main className="main-container">
      <Helmet>
        <title>Contact - Darpan Kattel</title>
        <meta
          name="description"
          content="All the mentionable contact information of Darpan Kattel is available here. You can also send him a message."
        />
        <meta
          name="keywords"
          content="contact, darpan, kattel, phone, mobile, number, email, address, message"
        />
      </Helmet>
      {sendMessageMutation.isLoading && <OverlayLoader text="Sending..." />}
      <div className="main-container-inner">
        <div className="titled-container">
          <h2 className="title">Contact me</h2>
          <div className="container">
            {contactDetails.map((detail, index) => (
              <a
                className="contact"
                href={detail.href}
                key={index}
                target={detail.title === "Address" ? "_blank" : "_self"}
              >
                {detail.icon}
                <div className="details">
                  <p className="title">{detail.title}</p>
                  <p className="detail">
                    {typeof detail.data == "string"
                      ? detail.data
                      : detail.data.constructor === Array
                      ? detail.data.map((text, index) => {
                          if (index !== detail.data.length - 1) {
                            return (
                              <>
                                {text}
                                <br />
                              </>
                            );
                          }
                          return text;
                        })
                      : null}
                  </p>
                </div>
              </a>
            ))}
          </div>
        </div>

        <div className="titled-container">
          <h2 className="title">
            {subjectFromUrl ? "Write Recommendations" : "Message me"}
          </h2>
          <div className="container contact">
            <form onSubmit={sendMessage}>
              <div className="input-box">
                <label>
                  <AiOutlineUser />
                </label>
                <input
                  type="text"
                  placeholder="Name"
                  value={message.name}
                  onChange={(e) =>
                    setMessage({ ...message, name: e.target.value })
                  }
                />
              </div>
              <div className="input-box">
                <label>
                  <MdMailOutline />
                </label>
                <input
                  type="text"
                  placeholder="Email"
                  value={message.email}
                  onChange={(e) =>
                    setMessage({ ...message, email: e.target.value })
                  }
                />
              </div>
              <div className="input-box">
                <label>
                  <MdSubject />
                </label>
                <input
                  type="text"
                  placeholder="Subject"
                  value={message.subject}
                  onChange={(e) =>
                    setMessage({ ...message, subject: e.target.value })
                  }
                />
              </div>
              <div className="input-box">
                <label>
                  <AiOutlineMessage />
                </label>
                <textarea
                  placeholder="Your message here..."
                  onChange={(e) =>
                    setMessage({ ...message, message: e.target.value })
                  }
                  value={message.message}
                ></textarea>
              </div>
              <div className="button-container">
                <button type="submit" className="button button-primary">
                  Send
                </button>
              </div>
            </form>
            <div className="image-container">
              <img src="images/man-cellphone.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Contact;
