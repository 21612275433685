import React from "react";
import { Image } from "antd";
import { MdOutlineNavigateNext } from "react-icons/md";
import { Link } from "react-router-dom";

function BlogWidget({ blog, darkMode }) {
  const { title, desc, slug, thumbnail } = blog;
  return (
    <div className="blog-container">
      <Image
        src={thumbnail}
        className="img"
        style={{
          backgroundColor: "white",
        }}
        alt={title}
      />
      <div className="blog-details">
        <h3 className="title">{title}</h3>
        <p className="desc">
          {desc.length > 200 ? desc.substring(0, 200) + "..." : desc}
        </p>
        <Link to={`/blog/${slug}`} className="blog-link">
          Read More{" "}
          <MdOutlineNavigateNext
            color={darkMode ? "#ffc107" : "#f1974d"}
            className="icon"
          />
        </Link>
      </div>
    </div>
  );
}

export default BlogWidget;
