import React from "react";
import { Spin } from "antd";

function Loader({ text = "Loading..." }) {
  return (
    <div className="spin-container overlay">
      <Spin size="large" className="spin-dot" />
      <p className="spin-text">{text}</p>
    </div>
  );
}

export default Loader;
