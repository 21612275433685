import React from "react";

function FileViewerUI({ className, children, title = "App" }) {
  return (
    <div className="file-viewer-ui">
      <div className="top">
        <div className="dots">
          <span className="dot red" />
          <span className="dot yellow" />
          <span className="dot green" />
        </div>
        <span className="file-name">{title}</span>
      </div>
      {children}
    </div>
  );
}

export default FileViewerUI;
