import React from "react";
import { Image } from "antd";
import { Carousel } from "antd";
import { AiFillStar } from "react-icons/ai";

import { recommendations } from "../text_data";
import { Link } from "react-router-dom";

function Recommendations() {
  return (
    <div className="container recommendation-container">
      <Carousel autoplay dots={{ className: "dots" }} autoplaySpeed={5000}>
        {recommendations.map((recommendation, index) => (
          <div className="recommendation" key={index}>
            <div className="recommendation-image-container">
              <Image
                src={
                  recommendation.image
                    ? recommendation.image
                    : "/images/user.png"
                }
                width={"100%"}
                className="recommendation-image"
                alt={`Image of ${recommendation.name}`}
              />
            </div>
            <h3 className="recommendation-title">{recommendation.name}</h3>
            <h3 className="recommendation-sub-title">{recommendation.post}</h3>
            <h4 className="recommendation-desc">{recommendation.message}</h4>
            <div className="stars">
              <span className="star">
                <AiFillStar color="#FFD700" />
              </span>
              <span className="star">
                <AiFillStar color="#FFD700" />
              </span>
              <span className="star">
                <AiFillStar color="#FFD700" />
              </span>
              <span className="star">
                <AiFillStar color="#FFD700" />
              </span>
              <span className="star">
                <AiFillStar color="#FFD700" />
              </span>
            </div>
          </div>
        ))}
      </Carousel>
      <div className="write-recommendations-container">
        <p className="title">Write your recommendations!</p>
        <p className="sub-title">
          Your recommendation, if liable, would be used!
        </p>
        <Link
          to="/contact/?subject=RECOMMENDATION"
          className="button button-primary"
        >
          Write Recommendations
        </Link>
      </div>
    </div>
  );
}

export default Recommendations;
