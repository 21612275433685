import React from "react";
import { BsEmojiFrown } from "react-icons/bs";

function Error({ err, defaultMessage }) {
  return (
    <main className="main-container blog-view">
      <div className="spin-container">
        <BsEmojiFrown className="spin-dot" />
        <p className="spin-text">
          {err.response.statusText || err.message || defaultMessage}
        </p>
      </div>
    </main>
  );
}

export default Error;
