import React from "react";
import { MdOutlineNavigateNext } from "react-icons/md";
import { Link } from "react-router-dom";

function Specializations({ specializations }) {
  return (
    <div className="titled-container">
      <h2 className="title">Specializations</h2>
      <div className="container specialization-container">
        {specializations.map((specialization, index) => (
          <div className="specialization" key={index}>
            <h3 className="specialization-title">{specialization.title}</h3>
            <h3 className="specialization-sub-title">
              {specialization.subTitle}
            </h3>
            <h4 className="specialization-desc">{specialization.desc}</h4>
            <Link to={specialization.orderHref} className="clickable">
              Order{" "}
              <MdOutlineNavigateNext
                color="#ffc107"
                style={{
                  fontSize: "1rem",
                  verticalAlign: "middle",
                }}
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Specializations;
