import React from "react";
import { Link } from "react-router-dom";

function HeroWidget({ data }) {
  const { title, subTitle, mainButton, buttons } = data;
  return (
    <div className="order-project-container">
      <div className="order-project-text">
        <h3 className="title">{title}</h3>
        <h4 className="sub-title">{subTitle}</h4>
      </div>
      <Link to={mainButton.href} className="button button-primary">
        {mainButton.title}
      </Link>
      {buttons?.length > 0 ? (
        <div className="buttons-container">
          {buttons?.map((button, index) => (
            <Link
              to={button.href}
              className="button button-secondary"
              key={index}
            >
              {button.title}
            </Link>
          ))}
        </div>
      ) : (
        <div className="empty-container">
          <br />
        </div>
      )}
    </div>
  );
}

export default HeroWidget;
