import React, { useState } from "react";
import { Image, message } from "antd";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getBlog, getDate, unsluggify, decodeBlogContent } from "../functions";
import Loader from "../components/Loader";
import Error from "../components/Error";
import BlogWidget from "../components/BlogWidget";
import { Helmet } from "react-helmet";

function BlogView({ darkMode }) {
  const { slug } = useParams();
  const [blog, setBlog] = useState({});
  window.scrollTo(0, 0);
  const { isFetching, isError, error } = useQuery(
    ["get-blog", slug],
    () => getBlog({ slug }),
    {
      onError: (err) => {
        message.error({
          content:
            err.response.statusText ||
            err.message ||
            "Couldn't fetch the blog!",
          className: darkMode ? "message" : "message light",
          duration: 3,
        });
      },
      onSuccess: (data) => {
        return setBlog(data.data);
      },
      refetchOnWindowFocus: false,
    }
  );

  return isFetching ? (
    <Loader />
  ) : blog?.title ? (
    <>
      <main className="main-container blog-view">
        <Helmet>
          <title>{blog?.title} | Blog - Darpan Kattel</title>
          <meta name="description" content={blog?.desc} />
          <meta name="keywords" content={blog?.keywords} />

          <meta
            property="og:title"
            content={blog?.title + " | Blog - Darpan Kattel"}
          />
          <meta property="og:image" content={blog?.thumbnail} />
          <meta property="og:description" content={blog?.desc} />
          <meta property="og:type" content="article" />
          <meta property="article:published_time" content={blog?.created} />
          <meta
            property="article:author"
            content={`${blog?.user.user.first_name} ${blog?.user.user.last_name}`}
          />
        </Helmet>
        <h1 className="title">{blog?.title}</h1>
        <div className="blog-view-inner">
          <div className="blog-thumbnail-container">
            <Image
              src={blog?.thumbnail}
              alt={blog?.title}
              className="blog-thumbnail"
            />
          </div>
          <div className="blog-detail">
            <article
              className="blog-content"
              dangerouslySetInnerHTML={{
                __html: decodeBlogContent(blog?.content),
              }}
            />
            <div className="blog-info">
              <div className="image-container">
                <Image
                  src={blog?.user.image}
                  className="img"
                  alt={`${blog?.user.user.first_name} ${blog?.user.user.last_name}`}
                />
              </div>
              <span className="format-head">Author: </span>
              <span className="data">{`${blog?.user.user.first_name} ${blog?.user.user.last_name}`}</span>
              <br />
              <span className="format-head">Created: </span>
              <span className="data">{getDate(blog?.created)}</span>
              <br />
              <span className="format-head">Updated: </span>
              <span className="data">{getDate(blog?.updated)}</span>
            </div>
          </div>
        </div>
        <div className="subjects-container">
          <p className="title">Tags:</p>
          <div className="subjects">
            {blog?.subject?.split(", ").map((subject) => (
              <span className="subject" key={subject}>
                {unsluggify(subject)}
              </span>
            ))}
          </div>
        </div>
      </main>
      {blog?.similar_blogs?.length > 0 && (
        <div className="main-container blog">
          <h2 className="title">Similar Blogs</h2>
          <div className="inner-container">
            {blog?.similar_blogs.constructor === Array
              ? blog?.similar_blogs.map((similar_blog) => (
                  <BlogWidget
                    blog={similar_blog}
                    darkMode={darkMode}
                    key={similar_blog?.slug}
                  />
                ))
              : null}
          </div>
        </div>
      )}
    </>
  ) : isError ? (
    <Error err={error} defaultMessage="Couldn't fetch the blog!" />
  ) : (
    "Unknown Error"
  );
}

export default BlogView;
