import React from "react";

function Footer({ data }) {
  const { currentDate, currentYear } = data;
  return (
    <footer>
      <p className="text">&copy; Darpan Kattel, 2022 - {currentYear}</p>
    </footer>
  );
}

export default Footer;
