import React from "react";

function ProjectsHeader({ title, subTitle, isProject }) {
  return (
    <header>
      {isProject ? <div className="absolute-title">Project</div> : null}
      <h1 className="title">{title}</h1>
      <h2 className="sub-title">{subTitle}</h2>
    </header>
  );
}

export default ProjectsHeader;
