import axios from "axios";
import { apiDomain } from "./config_own";
import { absoluteApiDomainOnly } from "./config_own";

export const getBlogs = async ({ currentPage, page_size = 6 }) => {
  const response = await axios.get(
    `${apiDomain}blogs?page=${currentPage}&page_size=${page_size}`
  );
  return response;
};

export const getBlog = async ({ slug }) => {
  const response = await axios.get(`${apiDomain}blogs/${slug}`);
  return response;
};

export const sendMessageToBackend = async (message) => {
  const response = await axios.post(`${apiDomain}message`, { ...message });
  return response;
};

export const getProjects = async () => {
  const response = await axios.get(`${apiDomain}projects`);
  return response;
};

export const getProject = async (slug) => {
  const response = await axios.get(`${apiDomain}projects/${slug}`);
  return response;
};

export const postOrder = async ({ order }) => {
  const response = await axios.post(`${apiDomain}order`, { ...order });
  return response;
};

export const getOrderStatus = async (reference_number) => {
  const response = await axios.get(`${apiDomain}order/${reference_number}`);
  return response;
};

export const getSearchResults = async (text) => {
  const response = await axios.get(`${apiDomain}blogs/search?q=${text}`);
  return response;
};

//utility functions

export const getDate = (date) => {
  return "29th Feb, 2022";
};

export const unsluggify = (slug) => {
  const result = slug.replace(/\-/g, " ");
  return result.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const setHtmlClass = () => {
  if (localStorage.getItem("theme") === "light") {
    document.documentElement.classList.add("light");
  } else {
    if (document.documentElement.classList.contains("light")) {
      document.documentElement.classList.remove("light");
    }
  }
};

export const decodeBlogContent = (text) => {
  return text
    .replaceAll("image-container", "blog-view-text-inner image-container")
    .replaceAll(
      'src="Images/blogs/',
      `src="${absoluteApiDomainOnly}media/images/blogs/`
    );
  // .replaceAll("<iframeown>", '<div class="iframe">')
  // .replaceAll("</iframeown>", "</div>")
  // .replaceAll("<link><", '<a href="')
  // .replaceAll(">*<", '">')
  // .replaceAll("></link>", "</a>");
};
