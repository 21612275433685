import React from "react";

function ViewOrderStatus({ viewOrderStatus, setViewOrderStatus }) {
  const { status } = viewOrderStatus?.response;
  return (
    <div className="spin-container overlay">
      <div className="order-status-container">
        <p className="reference-number">
          #<i>{viewOrderStatus?.response?.reference_number}</i>
        </p>
        <p className="status">Status {status}</p>
        <p className="text">
          <span className="name">
            Mr./Mrs./Ms. {viewOrderStatus?.response?.name?.split(" ")[0]},
          </span>
          {status == 1
            ? "Your order has just been placed, you will be asked for confirmation!"
            : status == 2
            ? "Your order has been confirmed, and the work will soon be started!"
            : status == 3
            ? "Your order is being ready, stay tuned!"
            : status == 4
            ? "Your order is in the fnishing stage, we will contact you soon!"
            : status == 5
            ? "Your order has been completed and will be delievered to you soon"
            : "Error"}
        </p>
        <button
          className="button button-primary"
          onClick={() => setViewOrderStatus({ show: false, response: {} })}
        >
          OK
        </button>
      </div>
    </div>
  );
}

export default ViewOrderStatus;
