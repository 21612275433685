import React from "react";
import { HiMenu, HiDotsVertical } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import { Switch } from "antd";
import { NavLink } from "react-router-dom";
import { setHtmlClass } from "../functions";

function RightContainer({ data }) {
  const {
    sidebarShown,
    setSidebarShown,
    navbarShown,
    setNavbarShown,
    darkMode,
    setDarkMode,
  } = data;
  const closeNav = () => {
    setNavbarShown(false);
  };
  return (
    <div className="right-container">
      <div className="mobile-top mobile-only">
        <button
          className="clickable  mobile-only"
          onClick={() => setSidebarShown(!sidebarShown)}
        >
          <HiDotsVertical color={darkMode ? "#fff" : "#000"} size={"18px"} />
        </button>
        <button
          className="clickable  mobile-only"
          onClick={() => setNavbarShown(true)}
        >
          <HiMenu color={darkMode ? "#fff" : "#000"} size={"20px"} />
        </button>
      </div>
      <div className={navbarShown ? "nav-container show" : "nav-container"}>
        <div
          className="nav-container-overlay mobile-only"
          onClick={() => setNavbarShown(false)}
        ></div>
        <nav>
          <IoMdClose
            color={"#8c8c8e"}
            className="cross-absolute left mobile-only"
            size={18}
            onClick={() => setNavbarShown(false)}
          />
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "nav-link" : "nav-link")}
            onClick={closeNav}
          >
            Home
          </NavLink>
          <NavLink
            to="/projects"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            onClick={closeNav}
          >
            Projects
          </NavLink>
          <NavLink
            to="/blog"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            onClick={closeNav}
          >
            Blog
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            onClick={closeNav}
          >
            Contact
          </NavLink>
          <NavLink to="/order" className="nav-link order" onClick={closeNav}>
            Order
          </NavLink>
          <div className="dark-mode-switch">
            <Switch
              checkedChildren={<MdDarkMode />}
              unCheckedChildren={<MdLightMode />}
              onChange={(checked) => {
                setDarkMode(!darkMode);
                localStorage.setItem("theme", !darkMode ? "dark" : "light");
                setHtmlClass();
              }}
              checked={!darkMode}
              style={{
                backgroundColor: darkMode ? "#ffc107" : "#f1974d",
              }}
            />
          </div>
        </nav>
      </div>
    </div>
  );
}

export default RightContainer;
