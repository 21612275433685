import React from "react";

import { Image } from "antd";
import { Progress } from "antd";

import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { AiOutlineYoutube } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import {
  absoluteApiDomain,
  facebookLink,
  instagramLink,
  linkedinLink,
  youtubeLink,
  forthLink,
} from "../config_own";

const languageData = [
  {
    name: "English",
    percent: 60,
  },
  {
    name: "Nepali",
    percent: 90,
  },
  {
    name: "Hindi",
    percent: 50,
  },
];

const frameworksData = [
  {
    name: "Djnago",
    percent: 75,
  },
  {
    name: "React.JS",
    percent: 60,
  },
  {
    name: "React Native",
    percent: 50,
  },
];

const programmingLanguagesData = [
  {
    name: "HTML/CSS",
    percent: 70,
  },
  {
    name: "C/C++",
    percent: 85,
  },
  {
    name: "Python",
    percent: 80,
  },
  {
    name: "JavaScript",
    percent: 70,
  },
  {
    name: "Java",
    percent: 60,
  },
  {
    name: "PHP",
    percent: 50,
  },
];

function Sidebar({ stateControl, darkMode }) {
  const { state, setState } = stateControl;
  return (
    <div className={state ? "left-sidebar show" : "left-sidebar"}>
      <div
        className="left-sidebar-overlay mobile-only"
        onClick={() => setState(false)}
      ></div>
      <div className="left-sidebar-container">
        <div className="left-sidebar-top">
          <IoMdClose
            color={darkMode ? "#ffc107" : "#f1974d"}
            size={18}
            className="cross-absolute right mobile-only"
            onClick={() => setState(!state)}
          />
          <Image
            src="/images/website-icon-transparent.png"
            width={"50%"}
            wrapperStyle={{
              borderRadius: "50%",
              overflow: "hidden",
            }}
            alt={"Image of Darpan Kattel | Website Logo"}
          />
          <h2>Darpan Kattel</h2>
          <h3>
            Developer,
            <br />
            <small>Engineering Student</small>
          </h3>
        </div>
        <div className="left-sidebar-middle">
          <div className="left-sidebar-middle-divs">
            <div className="line">
              <strong className="left-sidebar-middle-title">Residence:</strong>
              <p className="left-sidebar-middle-text">Nepal</p>
            </div>

            <div className="line">
              <strong className="left-sidebar-middle-title">College:</strong>
              <p className="left-sidebar-middle-text">IOE, Pulchowk Campus</p>
            </div>

            <div className="line">
              <strong className="left-sidebar-middle-title">Level:</strong>
              <p className="left-sidebar-middle-text">
                Bachelor's (2<sup>nd</sup> | 2<sup>nd</sup>)
              </p>
            </div>

            <div className="line">
              <strong className="left-sidebar-middle-title">Programme:</strong>
              <p className="left-sidebar-middle-text">Computer Eng'ing</p>
            </div>
          </div>

          <div
            className="left-sidebar-middle-divs"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {languageData.map((language, index) => (
              <Progress
                key={index}
                type="circle"
                strokeColor={darkMode ? "#ffc107" : "#f1974d"}
                percent={language.percent}
                width={75}
                strokeLinecap={"butt"}
                format={(percent) => (
                  <span
                    className={darkMode ? "text text-dark" : "text text-light"}
                  >
                    {language.name}
                    <span className="percent">{percent}%</span>
                  </span>
                )}
              />
            ))}
          </div>

          <div
            className="left-sidebar-middle-divs"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {programmingLanguagesData.map((language, index) => (
              <Progress
                key={index}
                strokeColor={darkMode ? "#ffc107" : "#f1974d"}
                percent={language.percent}
                steps={10}
                format={(percent) => (
                  <span
                    className={darkMode ? "text text-dark" : "text text-light"}
                  >
                    {language.name}
                    <span className="percent">{percent}%</span>
                  </span>
                )}
              />
            ))}
          </div>

          <div
            className="left-sidebar-middle-divs"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {frameworksData.map((language, index) => (
              <Progress
                key={index}
                strokeColor={darkMode ? "#ffc107" : "#f1974d"}
                percent={language.percent}
                steps={10}
                format={(percent) => (
                  <span
                    className={darkMode ? "text text-dark" : "text text-light"}
                  >
                    {language.name}
                    <span className="percent">{percent}%</span>
                  </span>
                )}
              />
            ))}
          </div>

          <div className="left-sidebar-middle-divs">
            <a
              href={`${absoluteApiDomain}cv/download`}
              className="button button-primary"
              target="_blank"
            >
              Download CV{" "}
              <FiDownload
                style={{
                  verticalAlign: "middle",
                }}
              />
            </a>
          </div>
        </div>
        <div className="left-sidebar-bottom">
          <a href={facebookLink} target={"_blank"}>
            <FaFacebookF />
          </a>
          <a href={instagramLink} target={"_blank"}>
            <FaInstagram />
          </a>
          <a href={linkedinLink} target={"_blank"}>
            <FaLinkedinIn />
          </a>
          <a href={youtubeLink} target={"_blank"}>
            <AiOutlineYoutube />
          </a>
          <a href={forthLink} target={"_blank"}>
            C
          </a>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
