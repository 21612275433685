import React, { useEffect, useState } from "react";
import "./styles/App.scss";
import "./styles/home.scss";

import Sidebar from "./components/Sidebar";
import RightContainer from "./components/RightContainer";
import Footer from "./components/Footer";

import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Order from "./pages/Order";
import Blog from "./pages/Blog";
import BlogView from "./pages/BlogView";
import Projects from "./pages/Projects";
import ProjectView from "./pages/ProjectView";
import { setHtmlClass } from "./functions";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

function App() {
  const [sidebarShown, setSidebarShown] = useState(true);
  const [navbarShown, setNavbarShown] = useState(false);
  const [darkMode, setDarkMode] = useState(() => {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", "dark");
    }
    if (localStorage.getItem("theme") === "dark") {
      return true;
    } else {
      return false;
    }
  });
  useEffect(() => {
    setHtmlClass();
  });
  return (
    <Router>
      <div className={!darkMode ? "app light" : "app dark"}>
        <Sidebar
          stateControl={{
            state: sidebarShown,
            setState: setSidebarShown,
          }}
          darkMode={darkMode}
        />
        <Helmet>
          <title>Darpan Kattel | Home</title>
          <meta
            name="description"
            content="A Personal website of Darpan Kattel, an Engineering Student and a developer, thriving to learn. This website has many of his recent projects, and also his CV."
          />
          <meta
            name="keywords"
            content="darpan, kattel, engineer, student, pulchowk, ioe, developer, programmer, coder, nepal, personal, portfolio, best, top, ui, ux, react, django"
          />

          <meta property="og:title" content="Darpan Kattel | Website | Home" />
          <meta property="og:image" content="/images/darpan-website.png" />
          <meta
            property="og:description"
            content="A Personal website of Darpan Kattel, an Engineering Student and a developer, thriving to learn. This website has many of his recent projects, and also his CV."
          />
          <meta property="og:type" content="website" />
        </Helmet>
        <div className="central-container">
          <RightContainer
            data={{
              sidebarShown,
              setSidebarShown,
              navbarShown,
              setNavbarShown,
              darkMode,
              setDarkMode,
            }}
          />

          <Routes>
            <Route exact path="/" element={<Home darkMode={darkMode} />} />
            <Route path="/projects" element={<Projects />} />
            <Route
              path="/projects/:slug"
              element={<ProjectView darkMode={darkMode} />}
            />
            <Route path="/blog" element={<Blog darkMode={darkMode} />} />
            <Route
              path="/blog/:slug"
              element={<BlogView darkMode={darkMode} />}
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/order" element={<Order darkMode={darkMode} />} />
          </Routes>

          <Footer
            data={{
              currentDate,
              currentYear,
            }}
          />
        </div>
      </div>
    </Router>
  );
}

export default App;
