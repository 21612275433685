import React, { useEffect, useState } from "react";
import { message, Pagination, Select } from "antd";
import "../styles/blog.scss";
import BlogHeader from "../components/BlogHeader";
import BlogWidget from "../components/BlogWidget";
import { useQuery, QueryClient, useMutation } from "react-query";
import { getBlogs, getSearchResults } from "../functions";
import Loader from "../components/Loader";
import Error from "../components/Error";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const { Option } = Select;

function Blog({ darkMode }) {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [totalBlogs, setTotalBlogs] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  window.scrollTo(0, 0);
  const { isFetching, isError, error, refetch } = useQuery(
    "get-blogs",
    () => getBlogs({ currentPage, page_size: pageSize }),
    {
      onError: (err) => {
        message.error({
          content:
            err.response.statusText ||
            err.message ||
            "Couldn't fetch the blog!",
          className: darkMode ? "message" : "message light",
          duration: 3,
        });
      },
      onSuccess: (data) => {
        setBlogs(data.data.results);
        setTotalBlogs(data.data.count);
      },
      refetchOnWindowFocus: false,
      staleTime: 5 * 1000 * 60,
      refetchInterval: 5 * 1000 * 60,
    }
  );
  useEffect(() => {
    refetch();
  }, [currentPage]);

  const searchBlogMutation = useMutation(
    (text) => {
      return getSearchResults(text);
    },
    {
      onSuccess: (data) => setSearchResults(data.data),
    }
  );

  const searchBlog = (text) => {
    searchBlogMutation.mutate(text);
  };

  return isFetching ? (
    <Loader />
  ) : blogs.length ? (
    <>
      <BlogHeader />
      <Helmet>
        <title>Blogs - Darpan Kattel</title>
        <meta
          name="description"
          content="Articles related to programming, tech, innovation, engineering and computers are available here."
        />
        <meta
          name="keywords"
          content="articles, blog, programming, tech, innovation, engineering, computers, physics, cyber, security, artificial, intelligence, machine, learning, best"
        />
      </Helmet>
      <main className="main-container blog">
        <h2 className="title">Blogs</h2>
        <div className="search">
          <Select
            showSearch={true}
            placeholder="Search blogs..."
            defaultActiveFirstOption={false}
            allowClear={true}
            bordered={true}
            loading={true}
            showArrow={false}
            filterOption={false}
            onSearch={searchBlog}
            popupClassName="dropdown-results"
          >
            {!searchBlogMutation.isFetching &&
            searchResults.constructor === Array
              ? searchResults?.map((result) => (
                  <Option key={result.slug}>
                    <Link to={`/blog/${result.slug}`}>{result.title}</Link>
                  </Option>
                ))
              : null}
          </Select>
        </div>
        <div className="inner-container">
          {blogs.constructor === Array
            ? blogs?.map((blog) => (
                <BlogWidget blog={blog} darkMode={darkMode} key={blog.slug} />
              ))
            : null}
        </div>
        <div className="paginator">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            onChange={(page) => {
              setCurrentPage(page);
            }}
            total={totalBlogs}
            showTotal={(total) => `Total: ${total} blogs`}
          />
        </div>
      </main>
    </>
  ) : isError ? (
    <Error err={error} defaultMessage="Couldn't fetch the blog!" />
  ) : (
    "Unknown Error"
  );
}

export default Blog;
