import React from "react";
import { Carousel } from "antd";

function BlogHeader() {
  return (
    <header className="blog-header">
      <div className="blog-name">
        <h2 className="title">Darpan's Circle</h2>
      </div>
      <Carousel autoplay>
        <div className="container programming">
          <div className="details">
            <h2>Programming and Tech</h2>
            <h3>
              World is moving, why don't you try to catch it? Just learn to
              program!
            </h3>
          </div>
        </div>
        <div className="container engineering">
          <div className="details">
            <h2>Innovation and Engineering</h2>
            <h3>Innovation requires learning of tech, follow me.</h3>
          </div>
        </div>
        <div className="container computers">
          <div className="details">
            <h2>Computers</h2>
            <h3>The base of the world's infrastructure!</h3>
          </div>
        </div>
      </Carousel>
    </header>
  );
}

export default BlogHeader;
