import { BiPhone, BiMap } from "react-icons/bi";
import { MdMailOutline } from "react-icons/md";

export const recommendations = [
  {
    name: "Bishnu Tiwari",
    post: "Client",
    message:
      "Darpan handles us, the clients, perfectly, and also the result was as we expected from him.",
    image: "/images/bishnu-tiwari.jpg",
  },
  {
    name: "Aakash B. Aryal",
    post: "Co-Worker",
    message:
      "Darpan is a good companion, helps me anytime, and has a great knowledge of his work. I love his projects.",
    image: "/images/aakash-bandhu-aryal.jpg",
  },
  {
    name: "Dipen Pandit",
    post: "Friend",
    message:
      "Well, it's difficult to term Darpan just as a friend after all, he gets to do his work so perfectly. We had participated on a Hackathon together, where we were the Winner.",
    image: "/images/dipen-pandit.jpg",
  },
  {
    name: "Simran Dhakal",
    post: "Co-Worker",
    message:
      "Darpan not just has great projects, but he manages to finish his projects within the guideline, he marks his words. Well, I love such dedication.",
    image: "/images/simran-dhakal.jpg",
  },
  {
    name: "Mandeep Thapa",
    post: "Developer",
    message:
      "I had just met him, we had a small project done, the time spent was great. Not only he is a team man, but he also has a good sense of humor.",
  },
  {
    name: "Albert R. Timsina",
    post: "Friend",
    message: "Modest, Good Companion, Confident, Matured and well concerned about the works done.",
  }
];

export const projectHeroWidgetData = {
  title: "Ready to order your project?",
  subTitle: "Let me know if you're just interested!",
  mainButton: {
    title: "Contact Me",
    href: "/contact",
  },
  buttons: [
    {
      title: "All Projects",
      href: "/projects",
    },
  ],
};

export const specializations = [
  {
    title: "Web Development",
    subTitle: "Fullstack",
    desc: "I am a fullstack web developer. I design and develop websites, both frontend and  backend. I use React.JS as a frontend tool and Django as a backend tool. Also, I have done projects on PHP.",
    orderHref: "/order?order_type=web",
  },
  {
    title: "Mobile Development",
    subTitle: "Android",
    desc: "I can also develop simple mobile apps, using React Native. Though, this is not my perfection, I have some projects in this domain too, you can have a lookout at the projects section.",
    orderHref: "/order?order_type=mobile",
  },
  {
    title: "Desktop Development",
    subTitle: "MS Windows",
    desc: "I can develop simple Desktop GUIs using wxWidgets in C++ and using .Net Framework in C# as well. I am more centered towards .Net Framework, and you can checkout my projects too.",
    orderHref: "/order?order_type=desktop",
  },
];

export const blogHeroWidgetData = {
  title: "Learn what I know!",
  subTitle: "Read my blogs and articles, on Tech and more.",
  mainButton: {
    title: "Blog",
    href: "/blog",
  },
  buttons: [],
};

export const contactDetails = [
  {
    title: "Phone",
    icon: <BiPhone className="icon" />,
    data: "9825******",
    href: "tel:9825000000",
  },
  {
    title: "Email",
    icon: <MdMailOutline className="icon" />,
    data: ["kattelktl@gmail.com", "077bct099.darpan@pcampus.edu.np"],
    href: "mailto:kattelktl@gmail.com",
  },
  {
    title: "Address",
    icon: <BiMap className="icon" />,
    data: "Kathmandu, Nepal",
    href: "https://maps.google.com/?q=kathmandu",
  },
];
