import React from "react";
import { Timeline } from "antd";

function Text({ text, date, preInst, instituition, current }) {
  return (
    <div className={current ? "timeline-text current" : "timeline-text"}>
      <p className="text">{text}</p>
      <p className="instituition">
        {preInst} <i>{instituition}</i>
      </p>
      <p className="date">{date}</p>
    </div>
  );
}

function EducationalBgContainer() {
  return (
    <div className="educational-bg-container">
      <div className="educational-bg">
        <h3 className="title">Educational Background</h3>
        <div className="inner">
          <Timeline
            pending="Studying..."
            mode="alternate"
            style={{ color: "white" }}
          >
            <Timeline.Item color="#ffc107">
              <Text
                text={"Grade 8 (DLE) | Completed"}
                preInst={"from"}
                instituition={"Adarsha Shishu English School (AMITY)"}
                date={"Apr, 2016 AD"}
              />
            </Timeline.Item>
            <Timeline.Item color="#ffc107">
              <Text
                text={"Grade 10 (SEE) | Completed"}
                preInst={"from"}
                instituition={"Adarsha Shishu English School (AMITY)"}
                date={"Apr, 2018 AD"}
              />
            </Timeline.Item>
            <Timeline.Item color="#ffc107">
              <Text
                text={"+2 (SLC) | Completed"}
                preInst={"from"}
                instituition={"Devi Secondary School"}
                date={"2020 AD"}
              />
            </Timeline.Item>
            <Timeline.Item color="#ffc107">
              <Text
                text={"Started Bachelor's in Computer Engineering"}
                preInst={"from"}
                instituition={"IOE, Purwanchal Campus"}
                date={"Apr, 2021 AD"}
              />
            </Timeline.Item>
            <Timeline.Item color="#ffc107">
              <Text
                text={"Continued Bachelor's in Computer Engineering"}
                preInst={"currently at"}
                instituition={"IOE, Pulchowk Campus"}
                date={"Apr, 2022 AD"}
                current={true}
              />
            </Timeline.Item>
          </Timeline>
        </div>
      </div>
    </div>
  );
}

export default EducationalBgContainer;
