import React from "react";
import HeroWidget from "../components/HeroWidget";
import EducationalBgContainer from "../components/EducationalBgContainer";
import Recommendations from "../components/Recommendations";
import Header from "../components/Header";
import ExperienceContainer from "../components/ExperienceContainer";
import Projects from "../components/Projects";
import Specializations from "../components/Specializations";

import {
  projectHeroWidgetData,
  blogHeroWidgetData,
  specializations,
} from "../text_data";

function Home({ darkMode }) {
  window.scrollTo(0, 0);
  return (
    <main className="main-container">
      <div className="main-container-inner">
        <Header />
        <ExperienceContainer />
        <Projects darkMode={darkMode} />
        <Specializations specializations={specializations} />

        <div className="titled-container">
          <h2 className="title">Recommendations</h2>
          <Recommendations />
        </div>

        <HeroWidget data={projectHeroWidgetData} />
        <hr color="#ffc107" />
        <EducationalBgContainer />
        <HeroWidget data={blogHeroWidgetData} />
      </div>
    </main>
  );
}

export default Home;
