import React, { useState } from "react";
import Project from "./Project";
import { MdOutlineNavigateNext } from "react-icons/md";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { getProjects } from "../functions";
import Loader from "../components/Loader";

function Projects({ darkMode }) {
  const [projectsData, setProjectsData] = useState([]);
  const { isFetching } = useQuery("projects", () => getProjects(), {
    onSuccess: (data) => {
      return data.data.constructor === Array
        ? setProjectsData(data.data.slice(0, 4))
        : null;
    },
    onError: (err) => console.log(err),
    refetchOnWindowFocus: false,
  });
  return isFetching ? (
    <Loader text="Loading Projects..." />
  ) : projectsData?.length > 0 ? (
    <div className="titled-container">
      <h2 className="title">My Projects</h2>
      <div className="container">
        {projectsData?.map((project) => (
          <Project project={project} key={project.slug} />
        ))}
      </div>
      <Link className="button button-secondary more-button" to="/projects">
        More{" "}
        <MdOutlineNavigateNext
          style={{
            fontSize: "1rem",
            verticalAlign: "middle",
          }}
        />
      </Link>
    </div>
  ) : (
    "Error getting projects"
  );
}

export default Projects;
